import React, { useState } from 'react';
import './style.scss';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import logo from '../../resources/images/logo/logo.png';
import appLogo from '../../images/app-logo.png';
import appStoreDownload from '../../resources/images/app-store.webp';
import googlePlayDownload from '../../resources/images/google-play-badge.png';
import ROUTERS from '../../constants/Routers';
import { getMixpanel } from '../../api/mixpanel';

const mixpanel = getMixpanel();
const androidDownload = false;

const Links = ({ open, isMobile, toggleModal }) => {
  const location = useLocation();

  if (!open && isMobile) return <ul />;

  return (
    <ul>
      {
        <li
          onClick={toggleModal}
          className={
            location.pathname === ROUTERS.TECHNOLOGIES ? 'active-route' : ''
          }
        >
          <Link to={ROUTERS.TECHNOLOGIES}>Tech</Link>
        </li>
      }
      <li
        className={location.pathname === ROUTERS.ABOUT ? 'active-route' : ''}
        onClick={toggleModal}
      >
        <Link to={ROUTERS.ABOUT}>About</Link>
      </li>
      {/* 
      <li onClick={toggleModal}>
      <a href="https://jobs.earkick.com/">Jobs</a>
      </li>
  */}
      <li onClick={toggleModal}>
        <a href="https://blog.earkick.com/">Blog</a>
      </li>
    </ul>
  );
};
const getColor = () => {
  const url = window.location.pathname.replace('/', '');

  const colors = {
    'try-now': '#fff7ac',
    technologies: '#fdd3ff',
    about: '#f3f2e9',
    default: '#fff7ac',
  };

  return colors[url] || colors['default'];
};

const trackLink = (e, link, eventName) => {
  e.preventDefault();
  mixpanel.track(eventName);
  window.open(link);
};

const trackLinkBlank = (e, link, eventName) => {
  console.log(`${eventName} clicked`);
  e.preventDefault();
  mixpanel.track(eventName);
  window.open(link, '_blank');
};

const Header = () => {
  const [openModal, onOpenModal] = useState(false);
  const [appDownloadHeaderVisible, setAppDownloadHeaderVisible] =
    useState(true);

  const toggleModal = () => {
    onOpenModal(!openModal);
  };

  return (
    <>
      {/* 
    <div className="header__wefounder">
      <center>
        <a href="https://wefunder.com/earkick" target="_blank">Invest in Us</a>
      </center>
    </div>
  */}
      {appDownloadHeaderVisible && (
        <div className="header__app-download">
          <div className="app-download-container">
            <div className="close-button">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setAppDownloadHeaderVisible(false)}
              />
            </div>
            <div className="app-logo">
              <img src={appLogo} alt="Earkick logo" loading="lazy" />
            </div>
            <div className="content">
              <div className="title">Earkick</div>
              <div className="description">Free app for Android and iPhone</div>
            </div>
            <div className="download-button">
              <a
                href="https://earkick.page.link/get"
                onClick={(e) =>
                  trackLink(
                    e,
                    'https://earkick.page.link/get',
                    'Download iOS app WD1',
                  )
                }
              >
                <button>Download</button>
              </a>
            </div>
          </div>
        </div>
      )}
      <div
        className={`header fixed ${
          appDownloadHeaderVisible ? '' : 'app-download-header-hidden'
        }`}
      >
        <div className="header__container">
          <div className="logo">
            <Link to={ROUTERS.HOME}>
              <img src={logo} alt="Earkick logo" loading="lazy" />
            </Link>
          </div>
          <div className="links">
            <Links isMobile={false} />
          </div>

          {!openModal && (
            <div className="toggle-modal">
              <FontAwesomeIcon icon={faBars} onClick={toggleModal} />
            </div>
          )}

          <div className={`download-buttons`}>
            <a
              href="https://earkick.go.link?adj_t=1gmtqodf&adj_campaign=headernav"
              target="_blank"
              rel="noreferrer"
              className="app-store"
              onClick={(e) =>
                trackLinkBlank(
                  e,
                  'https://earkick.go.link?adj_t=1gmtqodf&adj_campaign=headernav',
                  'Download iOS app WD1',
                )
              }
            >
              <img
                src={appStoreDownload}
                alt="appstore download button"
                loading="lazy"
              />
            </a>

            {androidDownload && (
              <a
                className="google-play"
                href="https://play.google.com/store/apps/details?id=com.earkick.SelfCareCompanion&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                onClick={(e) =>
                  trackLinkBlank(
                    e,
                    'https://play.google.com/store/apps/details?id=com.earkick.SelfCareCompanion&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
                    'Download Android app WD2',
                  )
                }
              >
                <img alt="Get it on Google Play" src={googlePlayDownload} />
              </a>
            )}
          </div>
        </div>

        <div className={`mobile-modal-container ${openModal ? 'active' : ''}`}>
          <div className="mobile-modal" style={{ background: getColor() }}>
            <div className="close-icon">
              <FontAwesomeIcon icon={faTimes} onClick={toggleModal} />
            </div>
            <Links open={openModal} isMobile={true} toggleModal={toggleModal} />
            <div className="mobile-appstore">
              <a
                href="https://earkick.go.link?adj_t=1gmtqodf&adj_campaign=headernav"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={appStoreDownload}
                  alt="App store download button"
                  loading="lazy"
                />
              </a>
            </div>
            {/* 
            <div className="mobile-googleplay">
              <a
                className="google-play"
                href="https://play.google.com/store/apps/details?id=com.earkick.SelfCareCompanion&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img alt="Get it on Google Play" src={googlePlayDownload} />
              </a>
            </div>
            */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
